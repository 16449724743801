import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LEG_STATUS_MAPPINGS } from "../../../../../../common/models/src/lib/constants/leg.constants";
import { ModalType } from "../../../../../../common/models/src/lib/enums/modal.enums";
import { ParentType } from "../../../../../../common/models/src/lib/enums/parent-type.enum";
import type { IGoodsResponseDto } from "../../../../../../common/models/src/lib/interfaces/goods.interface";
import {
	CelerumButtonDeleteGrid,
	CelerumButtonEditGrid,
} from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumConfirmModal } from "../../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumInteractiveStatus } from "../../../../../../common/ui/src/lib/components/celerum-interactive-status/celerum-interactive-status.component";
import { getFormattedCurrency } from "../../../../../../common/utils/src/lib/helpers/currency.helpers";
import styles from "./goods-accordion-header.module.css";

interface GoodAccordionHeaderProps {
	goods: IGoodsResponseDto;
	parentType: ParentType;
	showEditButton?: boolean;
	showDeleteButton?: boolean;
	handleDeleteGoods: (id: number, name: string) => void;
	setSelectedGoods: (goods: IGoodsResponseDto) => void;
}

export const GoodsAccordionHeader = ({
	goods,
	showEditButton = true,
	showDeleteButton = true,
	setSelectedGoods,
	handleDeleteGoods,
	parentType,
}: GoodAccordionHeaderProps) => {
	const { id, name, clearCustomsLegStatusId, revenue, currency } = goods;

	const navigate = useNavigate();

	const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

	const handleNavigate = () => {
		navigate(`/jobs2/${goods.jobId}`, {
			state: { from: window.location.pathname },
		});
	};

	const renderRevenue = () => {
		return (
			<div className={styles.revenue}>
				<span className={styles.revenue__value}>
					{getFormattedCurrency(revenue, currency?.code)}
				</span>
			</div>
		);
	};

	return (
		<>
			<div className={styles.container}>
				{parentType === ParentType.Load ? (
					<div className={styles.nameWrapper}>
						<div className={styles.title}>
							<span
								className={styles.link}
								onClick={handleNavigate}
								onKeyUp={handleNavigate}
							>
								{goods.jobNumber}
							</span>
						</div>
						<span className={styles.title}>{name}</span>
					</div>
				) : (
					<div className={styles.nameWrapper}>
						<span className={styles.title}>{name}</span>
					</div>
				)}

				<div className={styles.actions}>
					{renderRevenue()}
					{parentType === ParentType.Load && clearCustomsLegStatusId && (
						<div>
							<CelerumInteractiveStatus
								status={goods?.clearCustomsLegStatusId || 0}
								statusMappings={LEG_STATUS_MAPPINGS}
								isInteractive={false}
							/>
						</div>
					)}
					{showEditButton && (
						<CelerumButtonEditGrid
							title="Edit"
							onClick={(event) => {
								event.stopPropagation();
								setSelectedGoods(goods);
							}}
						/>
					)}
					{showDeleteButton && (
						<CelerumButtonDeleteGrid
							title="Delete"
							onClick={(event) => {
								event.stopPropagation();
								setOpenConfirmModal(true);
							}}
						/>
					)}
				</div>
			</div>

			<CelerumConfirmModal
				type={
					parentType === ParentType.Load ? ModalType.Warning : ModalType.Delete
				}
				entityName="goods"
				entityProperty="named"
				entityValue={name}
				isOpen={openConfirmModal}
				title={`Are you sure you want to remove ${name} from this load?`}
				subtitle={
					parentType === ParentType.Job
						? "The goods cannot be recovered."
						: "This action can be reverted afterwards."
				}
				handleSubmit={() => {
					handleDeleteGoods(id, name);
					setOpenConfirmModal(false);
				}}
				handleClose={() => setOpenConfirmModal(false)}
			/>
		</>
	);
};
