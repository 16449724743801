import { Button } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import {
	downloadIcon,
	fileIcon,
	filesErrorIcon,
	trashIcon,
	uploadIcon,
} from "@progress/kendo-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { ContentType, type ViewAttachmentResponse } from "../api/JobApi";
import { DFlex } from "../display/DFlex";
import { type AttachmentUsageType, jobApi, toasted } from "../helpers";
import { useDialog } from "../helpersReact";

type DocumentFormProps = {
	documents: ViewAttachmentResponse[];
	noUpload?: boolean;
	onDocumentDelete: (id: number) => void;
	onDocumentDownload: (id: number) => void;
	onDocumentInsert: (files: FileList) => void;
};
const DocumentForm = ({
	documents,
	noUpload,
	onDocumentDelete,
	onDocumentDownload,
	onDocumentInsert,
}: DocumentFormProps) => {
	const [files, setFiles] = useState<FileList>();
	const inputRef = useRef<HTMLInputElement>(null);
	return (
		<DFlex column>
			<DFlex column gap="8px">
				{documents.length === 0 && (
					<DFlex gap="8px">
						<SvgIcon icon={filesErrorIcon} />
						No documents
					</DFlex>
				)}
				{documents.map((document) => (
					<DFlex key={document.id} spaceBetween>
						<DFlex gap="8px">
							<SvgIcon icon={fileIcon} />
							{document.name}
						</DFlex>
						<DFlex gap="8px">
							<Button
								svgIcon={downloadIcon}
								size="small"
								themeColor="primary"
								onClick={() => onDocumentDownload(document.id)}
							>
								Download
							</Button>
							<Button
								svgIcon={trashIcon}
								size="small"
								themeColor="error"
								onClick={() => onDocumentDelete(document.id)}
							>
								Delete
							</Button>
						</DFlex>
					</DFlex>
				))}
			</DFlex>
			{noUpload || (
				<DFlex spaceBetween>
					<input
						type="file"
						multiple
						ref={inputRef}
						onChange={(e) => setFiles(e.target.files ?? undefined)}
					/>
					<Button
						themeColor="primary"
						svgIcon={uploadIcon}
						onClick={() => {
							files && onDocumentInsert(files);
							setFiles(undefined);
							if (inputRef.current) {
								inputRef.current.value = "";
							}
						}}
						disabled={!files}
					>
						Upload
					</Button>
				</DFlex>
			)}
		</DFlex>
	);
};

type DocumentsFormWithDTOProps = {
	entityId: number;
	usage: AttachmentUsageType;
	isPod?: boolean;
	isInvoice?: boolean;
	noUpload?: boolean;
	onChange?: () => void;
};
const DocumentsFormWithDTO = ({
	entityId,
	usage,
	isPod,
	isInvoice,
	noUpload,
	onChange,
}: DocumentsFormWithDTOProps) => {
	const data = useQuery({
		queryKey: [
			"jobApi.attachment.attachmentListDetail",
			entityId,
			usage,
			isPod,
			isInvoice,
		],
		queryFn: () =>
			jobApi.attachment
				.attachmentListDetail({ entityId, usage, isPod, isInvoice })
				.then((x) => x.data),
		initialData: [],
	});
	return (
		<DocumentForm
			noUpload={noUpload}
			documents={data.data}
			onDocumentDelete={(id) =>
				toasted(
					jobApi.attachment
						.attachmentDelete(id)
						.then(() => data.refetch())
						.then(() => onChange?.()),
					"Deleting document",
				)
			}
			onDocumentDownload={(id) => {
				toasted(
					jobApi.attachment.attachmentDetail(id).then((x) => {
						const link = document.createElement("a");
						link.href = x.data;
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}),
					"Downloading document",
				);
			}}
			onDocumentInsert={(files) => {
				toasted(
					jobApi.attachment
						.attachmentCreate(
							{ entityId, isPod, usage },
							{ files: Array.from(files) },
							{ headers: { "Content-Type": ContentType.FormData } },
						)
						.then(() => data.refetch())
						.then(() => onChange?.()),
					"Inserting document",
				);
			}}
		/>
	);
};

export const useDocumentsFormDialog = () => {
	const [props, setProps] = useState<DocumentsFormWithDTOProps>();

	const [_toggleDialog, dialog] = useDialog(
		props && <DocumentsFormWithDTO {...props} />,
		"Documents",
	);

	const toggleDialog = (props?: DocumentsFormWithDTOProps) => {
		setProps(props);
		_toggleDialog(props);
	};

	return [toggleDialog, dialog] as const;
};
